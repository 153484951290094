<style scoped lang="scss">
/deep/ .comp-scanserial-el-dialog {
  margin-top: 6vh !important;
  max-width: 520px;
  .serial-table {
    .btn {
      padding: 4px;
    }
  }
  .scan-serials {
    margin-top: 15px;
    padding: 10px;
    border: 1px solid #eee;
    border-radius: 6px;
    line-height: 1.8;
    .scan-nodata {
      color: #999;
    }
  }
  .asset-image {
      width: 26px;
      height: 26px;
      vertical-align: middle;
      .error-image {
          font-size: 26px;
          color: #cacaca;
      }
  }
}
/deep/ .comp-scanserial-pro-el-dialog {
  max-width: 800px;
  .asset-image {
      width: 26px;
      height: 26px;
      vertical-align: middle;
      .error-image {
          font-size: 26px;
          color: #cacaca;
      }
  }
}
.v-modal {
  opacity: 0.1;
}
</style>

<template>
  <div>
    <el-dialog :title="title" custom-class="c-el-dialog comp-scanserial-el-dialog" :visible.sync="showDialog" 
      :before-close="_close" :close-on-click-modal="false" append-to-body>
      <div class="mrgb10" v-if="curProduct"><span class="fwb">当前资产：</span>{{ curProduct.assetName }}</div>
      <div class="mrgb10">
        <el-input size="medium" placeholder="扫码枪扫描后，将会自动添加!" clearable
          :ref="refScanSerialFocus" v-model="curSerial" @keyup.enter.native="_add()" @blur="autoFocus()">
          <template slot="append" v-if="!$root.isPc"><span @click="openSerialHtmlScan()"><i class="fa fa-qrcode"></i></span></template>
        </el-input>
      </div>
      <el-table class="serial-table" :data="serialTables" border>
        <el-table-column label="序列号" prop="serialNumber"></el-table-column>
        <el-table-column label="资产信息" min-width="135" v-if="!curProduct">
            <template slot-scope="scope">
                <el-image class="asset-image" v-if="scope.row.imageUrl"
                    :src="uploadBaseUrl + scope.row.imageUrl" 
                    :preview-src-list="[uploadBaseUrl + scope.row.imageUrl]">
                    <div slot="error">
                        <i class="el-icon-picture-outline error-image"></i>
                    </div>
                </el-image>
                <span>{{ mxTranceAssetData(scope.row) }}</span>
                <span :class="mxTranceAssetData(scope.row) ? '' : 'mrgl10'">{{ scope.row.name }}</span>
            </template>
        </el-table-column>
        <el-table-column label="操作" width="50" fixed="right">
          <template slot-scope="scope">
              <el-button class="btn" type="danger" size="mini" icon="el-icon-delete"
                @click="_deleteSerial(scope.row, scope.$index)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="scan-serials">
        <div class="scan-nodata">
          <div>温馨提示：</div>
          <div>1、扫码完成将自动查询资产信息，并添加列表；</div>
          <div>2、序列号去重，同一序列号只会成功添加一次；</div>
        </div>
      </div>
      <span slot="footer">
        <el-button size="small" @click="_close()">关闭</el-button>
        <el-button size="small" type="primary" @click="_submit()">结束扫码 [数量：{{ serialTables.length }}]</el-button>
      </span>
    </el-dialog>
    <el-dialog title="请选择需要的序列号" custom-class="c-el-dialog comp-scanserial-pro-el-dialog" :visible.sync="showDialogPro" :before-close="_closePro" :close-on-click-modal="false">
      <el-table :data="tableData">
        <el-table-column label="序列号" prop="serialNumber"></el-table-column>
        <el-table-column label="资产信息" min-width="135">
            <template slot-scope="scope">
                <el-image class="asset-image" v-if="scope.row.imageUrl"
                    :src="uploadBaseUrl + scope.row.imageUrl" 
                    :preview-src-list="[uploadBaseUrl + scope.row.imageUrl]">
                    <div slot="error">
                        <i class="el-icon-picture-outline error-image"></i>
                    </div>
                </el-image>
                <span>{{ mxTranceAssetData(scope.row) }}</span>
                <span :class="mxTranceAssetData(scope.row) ? '' : 'mrgl10'">{{ scope.row.name }}</span>
            </template>
        </el-table-column>
        <el-table-column label="操作" min-width="65" fixed="right">
          <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="selectPro(scope.row, scope.$index)">选择</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <!--调用摄像头扫码-->
    <html-scan :ref="refHtmlScan" :reader="'scanSerialReader'" @success="htmlScanSuccess(arguments)"></html-scan>
  </div>
</template>

<script>
import configMixins from "@/mixins/config"
import * as funStock from "@/api/stock"
import HtmlScan from "@/components/HtmlScan"
import { Weixin } from '@/utils/wx-jssdk.js'
import * as funCommon from "@/api/common"
export default {
  name: "scanSerial",
  mixins: [configMixins],
  components: { HtmlScan },
  data() {
    return {
      title: "扫描序列号二维码",
      refScanSerialFocus: "refScanSerialAutoFocus",
      refHtmlScan: "refScanSerialToHtmlScan",
      showDialog: false,
      curProduct: null,
      curSerial: "",
      curParams: {
        assetId: "",
        assetName: "",
        barCode: "",
        needSerialNumber: false,
        count: -1,
        imageUrl: "",
        category: {},
        maxCount: -1,
        serialNumber: "",
      },
      listQuery: {
        assetId: "",
        ownerType: null,
        ownerId: "",
        keywords: "",
        maxResultCount: 20
      },
      serialTables: [],
      // 选择出库商品
      showDialogPro: false,
      tableData: [],
      isInStock: false
    }
  },
  methods: {
    open(params, row) {
      this.showDialogPro = false
      this.isInStock = false
      this.curProduct = null
      this.curSerial =""
      this.listQuery.assetId = ""
      this.listQuery.ownerType = null
      this.listQuery.ownerId = ""
      this.listQuery.keywords = ""
      this.serialTables = []
      this.tableData = []
      // 第一个参数，获取需要的配置
      if (params) {
        this.listQuery.ownerId = params.ownerId || ""
        this.listQuery.ownerType = params.ownerType || null
        this.isInStock = params.isBuyIn || false // 采购入库
      }
      // 第二个参数，判断是否为单个商品添加序列号
      if (row) {
        this.setDefaultSerialData(row)
      } else {
        // 自动打开扫码
        setTimeout(() => {
          this.openSerialHtmlScan()
        }, 100)
      }
      this.showDialog = true
      this.autoFocus()
    },
    setDefaultSerialData(row) {
      this.curProduct = JSON.parse(JSON.stringify(row))
      this.serialTables = this.curProduct.serialNumbers.map(x => {
        let temp = {}
        for (let i in this.curParams) {
          temp[i] = this.curProduct[i]
        }
        temp.serialNumber = x
        return temp
      })
    },
    autoFocus() {
      this.$nextTick(() => {
        this.curSerial =""
        // 移动端不默认聚焦
        if (!window.$util.isMobileDevice()) {
          this.$refs[this.refScanSerialFocus].focus()
        }
      })
    },
    async getWechatSign() {
        await funCommon.GetWechatTicket().then(res => {
            let jssdkRes = res
            let wxJS = new Weixin()
            wxJS.init({
                appId: jssdkRes.appId, // 必填，公众号的唯一标识
                timestamp: jssdkRes.timestamp, // 必填，生成签名的时间戳
                nonceStr: jssdkRes.nonceStr, // 必填，生成签名的随机串
                signature: jssdkRes.signature // 必填，签名
            })
            wxJS.scanQRCode(wres => {
                this.htmlScanSuccess([wres.resultStr])
            }, 2)
        })
    }, 
    // 打开序列号扫码
    openSerialHtmlScan() {
        let browser = window.$util.getBrowser()
        if (browser && browser.wechat) {
            this.getWechatSign()
        } else {
          if (!this.$root.isPc) {
            this.$refs[this.refHtmlScan].open(true)
          }
        }
    },
    // 调用摄像头扫码成功
    htmlScanSuccess(arr) {
      this.curSerial = arr[0]
      this._add()
    },
    _add() {
      if (!this.curSerial) {
        this.warningMsg("请扫描商品序列号!")
        return false
      }
      // 采购入库
      if (this.isInStock) {
        if (this.curProduct) {
          // 为单个商品添加序列号
          // 不管是输入或者扫码，都认为是序列号
          let qrCodeItem = this.parsingQRCode(this.curSerial)
          let val = qrCodeItem.scanCode || ""
          let item = {}
          for (let i in this.curParams) {
            item[i] = this.curProduct[i]
          }
          item.serialNumber = val
          this.commonAdd(item, true)
        } else {
          // 直接扫码，认为是需要转换的二维码，并查询商品
          this.getInStockProduct()
        }
      } else {
        this.getOutStockProduct()
      }
      // 清空扫码框的值
      this.curSerial =""
    },
    // 入库查询商品
    getInStockProduct() {
      let qrCodeItem = this.parsingQRCode(this.curSerial)
      let val = qrCodeItem.scanCode || ""
      let barCode = qrCodeItem.bar_code || ""
      if (!barCode) {
        this.errorMsg("资产信息有误，请确认二维码是否正确!")
        return false
      }
      let searchDatas = []
      let query = { 
          categoryId: null,
          companyId: "",
          ownerType: null,
          ownerId: "",
          keywords: barCode,
          maxResultCount: 20
      }
      funStock.getStockSearch(query).then(res => {
        if (!res || res.length <= 0) {
          this.errorMsg("资产不存在!")
        } else {
          // 只显示有序列号的资产
          this.tableData = res.filter(x => {
            return x.hasSerialNumber
          })
          // 多个商品，则弹出手动选择商品界面
          if (res.length > 1) {
            this.showDialogPro = true
          } else {
            // 只有一条商品数据，则自动识别
            let item = this.tableData[0]
            item.serialNumber = val
            this.commonAdd(item)
          }
        }
      }).catch(e => {
          this.$alert('资产加载出错!')
          return false
      })
    },
    // 出库序列号查询商品
    getOutStockProduct() {
      window.$common.fullLoading()
      let qrCodeItem = this.parsingQRCode(this.curSerial)
      let val = qrCodeItem.scanCode
      this.listQuery.keywords = val
      // 有指定资产，则只能在当前资产中查询
      if (this.curProduct) {
        this.listQuery.assetId = this.curProduct.assetId || ""
      }
      funStock.getStockSnSearch(this.listQuery).then(res => {
        window.$common.closeFullLoading()
        if (!res || res.length <= 0) {
          this.errorMsg("序列号不存在!")
        } else {
          this.tableData = res.map(x => {
            return {
              ...x.asset,
              serialNumber: x.sn
            } 
          }) || []
          // 存在列表中的就排除，方便选择不同的序列号
          // 只显示有序列号的资产
          this.tableData = this.tableData.filter(x => {
            let index = this.serialTables.findIndex(t => !t.needSerialNumber || t.serialNumber === x.serialNumber)
            return index === -1
          })
          console.log(this.serialTables)
          let len = this.tableData.length
          if (len > 1) {
            // 多个商品，则弹出手动选择商品界面
            this.showDialogPro = true
          } else if (len === 1) {
            // 有拥有者，则需要判断库存
            let item = this.tableData[0]
            this.commonAdd(item)
          } else {
            // 去重后提示已添加完成
            this.warningMsg("相关序列号已全部添加到列表!")
            return false
          }
        }
      })
    },
    selectPro(row, index) {
      this.commonAdd(row)
      this._closePro()
    },
    commonAdd(item, isNotTrance) {
      let index = this.serialTables.findIndex(x => x.serialNumber === item.serialNumber)
      if (index > -1) {
        this.warningMsg("序列号已存在!")
        return false
      }
      if (isNotTrance) {
        // 为单个商品添加，数据格式不需要转换
        this.serialTables.push(item)
      } else {
        // 其他需要转换一下数据格式
        let curItem = {}
        curItem.assetId = item.id
        curItem.assetName = item.name
        curItem.barCode = item.barCode
        curItem.needSerialNumber = item.hasSerialNumber || false
        curItem.serialNumber = item.serialNumber || ""
        curItem.imageUrl = item.imageUrl
        curItem.category = item.category
        curItem.maxCount = this.listQuery.ownerId ? item.stock : -1 // 设置为-1，表示不需要控制最大数量
        this.serialTables.push(curItem)
      }
      // 非单个商品扫码，直接连续扫码
      if (!this.curProduct) {
        this.openSerialHtmlScan()
      }
    },
    _deleteSerial(row, index) {
      this.serialTables.splice(index, 1)
    },
    _submit() {
      // 执行方式跟选择批次一样，数据格式也一样
      this.$emit("scanSerials", this.serialTables, this.curProduct)
      this._close()
      // this.successMsg("添加成功!")
    },
    _close() {
      this.showDialog = false
    },
    _closePro() {
      this.showDialogPro = false
    }
  }
}
</script>