<style scoped lang="scss">
  .comp-drag-multiple-imageupload-box {
    .upload-btn {
      float: left;
    }
    /deep/ .upload-btn .el-upload {
      width: 100px;
      height: 100px;
      line-height: 100px;
      border: 1px dotted #999;
      color: #999;
      font-size: 32px;
      font-weight: normal;
      border-radius: 4px;
    }
    .images {
      margin: 0 10px 10px 0;
      float: left;
      width: 100px;
      height: auto;
      border-radius: 4px;
      position: relative;
      background-color: #f4f4f4;
      .image {
        display: block;
        width: 100px;
        height: 100px;
        background-color: #ffffff;
        border: 1px dotted #f0f9ff;
      }
      .btns {
        display: flex;
        justify-content: space-evenly;
        width: 96px;
        height: 22px;
        padding: 0 2px;
        border: 1px dotted #999;
        border-top: 0;
        align-items: center;
      }
      .btn {
        display: inline-block;
        width: 16px;
        height: 16px;
        line-height: 16px;
        text-align: center;
        background: #fefefe;
        border: 1px solid #ddd;
        cursor: pointer;
      }
      .btn.edit {
        left: 2px;
        color: #1f7dff;
      }
      .btn.prev {
        left: 28px;
      }
      .btn.next {
        right: 28px;
      }
      .btn.del {
        right: 2px;
        color: #ff0000;
      }
    }
  }
</style>

<template>
  <div class="comp-drag-multiple-imageupload-box">
    <div class="images" v-for="(img, index) in imgUrls" :key="img">
      <el-image class="image" :src="img" :preview-src-list="previewUrls" @click="setPreviewUrls" :z-index="9999999990"></el-image>
      <div class="btns" v-if="!justQuery">
        <el-upload class="btn edit" :show-file-list="false" title="修改"
          accept="image/jpeg,image/gif,image/png"
          :headers="uploadHeaders"
          :action="uploadUrl" 
          :data="uploadData"
          :on-success="(res, file, fileList) => { handleAvatarSuccess(res, file, fileList, index) }"
          :on-error="handleAvatarError"
          :before-upload="beforeAvatarUpload">
          <template>
            <i class="el-icon-edit"></i>
          </template>
        </el-upload>
        <a class="btn prev" @click="movePrev(index)" title="左移" v-if="index > 0"><i class="el-icon-back"></i></a>
        <a class="btn next" @click="moveNext(index)" title="右移" v-if="index < imgUrls.length - 1"><i class="el-icon-right"></i></a>
        <a class="btn del" @click="deleteUrl(index)" title="删除"><i class="el-icon-delete"></i></a>
      </div>
    </div>
    <template v-if="!justQuery">
      <el-upload class="upload-btn" :show-file-list="false" :multiple="uploadMultiple"
        accept="image/jpeg,image/gif,image/png"
        :headers="this.uploadHeaders"
        :action="uploadUrl" 
        :data="uploadData"
        :on-success="handleAvatarSuccess"
        :on-error="handleAvatarError"
        :before-upload="beforeAvatarUpload">
        <template>
          <i class="el-icon-plus"></i>
        </template>
      </el-upload>
    </template>
    <div class="clearb"></div>
  </div>
</template>

<script>
import * as funCommon from "@/api/common"
export default {
  name: "compImageMultipleUpload",
  props: {
    folder: String,
    fileName: String,
    imageUrls: {
      default: () => [],
      type: Array
    },
    justQuery: {
      default: false,
      type: Boolean
    },
    multiple: Boolean
  },
  data() {
    return {
      uploadUrl: "", 
      uploadData: {
        ContainerName: this.folder || "",
        Name: this.fileName || ""
      },
      previewUrls: [],
      uploadMultiple: this.multiple || false
    }
  },
  computed: {
    imgUrls() {
      let imgs = []
      imgs = this.imageUrls.map(x => {
        let url = window.$util.isUrl(x) ? x : (this.uploadBaseUrl + x)
        return url
      })
      return imgs
    }
  },
  mounted() {
    // 数据初始化
    this.uploadUrl = this.uploadBaseUrl + "/api/blob"
  },
  methods: {
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10
      if (!isLt2M) {
          this.errorMsg('上传图片大小不能超过 10MB!')
          return false
      }
      window.$common.fullLoading()
      return true
    },
    handleAvatarSuccess(res, file, fileList, index) {
      window.$common.closeFullLoading()
      this.emitImageUrls(index, res.url)
    },
    handleAvatarError() {
      window.$common.closeFullLoading()
    },
    setPreviewUrls() {
      this.previewUrls = []
      setTimeout(() => {
        this.previewUrls = this.imgUrls || []
      }, 100)
    },
    movePrev(index) {
      if (index < 1) {
        this.warningMsg("已是第一个!")
        return false
      }
      this.swiperSort(this.imgUrls, index, index - 1)
      this.emitAllImages()
    },
    moveNext(index) {
      if (index >= this.imgUrls.length - 1) {
        this.warningMsg("已是最后一个!")
        return false
      }
      this.swiperSort(this.imgUrls, index, index + 1)
      this.emitAllImages()
    },
    deleteUrl(index) {
      // 删除服务器的图片
      funCommon.delBlob(this.imgUrls[index].replace(this.uploadBaseUrl, "")).then(res => {
        // 删除
        this.emitImageUrls(index, null)
      }).catch(ex => {
        // 删除
        this.emitImageUrls(index, null)
      })
    },
    emitAllImages() {
      // 传3个值表示是移动数据，图片传所有的过去
      this.$emit("emitImageUrls", -1, "", this.imgUrls)
    },
    emitImageUrls(index, url) {
      // 传两个值，第一个值是下标，第二个值是图片地址，不传url表示删除
      this.$emit("emitImageUrls", index !== undefined ? index : -1, url)
    }
  }
}
</script>